<template>
  <multiselect
      class="with-border"
      track-by="id"
      label="name"
      v-model="item"
      :searchable="true"
      :showLabels="false"
      :allow-empty="false"
      placeholder="Selecionar..."
      :options="items"
      @select="onItemSelect"
      @search-change="debounceItems"
  >
    <template #caret>
      <div v-if="!item" class="chevron">
        <ChevronDown />
      </div>
    </template>
    <template #singleLabel="{ option }">
      <ItemLine :item="option" />
    </template>
    <template #option="{option}">
      <ItemLine :item="option"/>
    </template>
    <template #noResult>Nenhum Procedimento encontrado</template>
    <template #noOptions>
      <span class="text-primary title__l">Digite para Pesquisar Itens</span>
    </template>
  </multiselect>
</template>
<script>
import {debounce, isArray} from 'lodash'

export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ItemLine: () => import('@/components/General/ItemLine')
  },
  props: {
    /**
     * @param {Object} budget
     */
    value: Object,
    clinic_id: String,
  },
  data() {
    return {
      debounceItems: debounce(this.searchItems, 300),
      item: null,
      items: [],
    }
  },
  methods: {
    onItemSelect(item) {
      item.health_plan_name = this.value.health_plan_id.fantasy_name
      item.health_plan_id = this.value.health_plan_id.clinic_health_plan[0]?.id
      this.$emit('onItemSelect', item)
    },
    searchItems(query) {
      if(this.value?.health_plan_id?.clinic_health_plan[0]?.invoicing_tiss_active && this.value?.health_plan_id?.clinic_health_plan[0]?.invoicing_tiss_setting?.id){
        this.getTussProcedures(query)
        return
      }
      this.getItems(query)
    },
    getItems(query) {
      this.api.getItems(this.clinic_id, query)
      .then(res => {
        this.items = res.data;
      })
      .catch(err => this.$toast.error(err.message))
    },
    getTussProcedures(query) {
      this.api.getTissTableProcedures(
        this.value?.health_plan_id?.clinic_health_plan[0]?.invoicing_tiss_setting?.id,
        query,
        this.clinic_id
      )
      .then(res => {
        if(res.data && isArray((res.data))) {
          this.items = res.data
        }
        else {
          this.items = []
        }
      })
      .catch(err => this.$toast.error(err.message))
    },
  },
  watch: {
    /**
     * Just to reset the select when a item is added
     * @param newV
     * @param oldV
     */
    'value.items'(newV, oldV) {
      if(newV.length === oldV.length) {
        this.item = null
      }
    },
    'value.health_plan_id'(newV, oldV) {
      this.items = []
    }
  }
}
</script>
<style lang="scss" scoped>
.with-border {
  border-radius: 1rem;
}
.disabled-description {
  font-size: 14px;
  font-style: italic;
  color: var(--type-active);
}
</style>